import { localDataUser, localDataApp, localDataDic, localDataPermission } from '@/utils/local-data';
import { updateToken } from '@greatmap/uums-modules';

// appId，appKey 数据 Object
export const APP_DATA = localDataApp.get() || {};
APP_DATA.appKey = window.CONFIG.APP_MANAGER_KEY;

// 用户信息 Object
export const USER_DATA = localDataUser.get() || {};
updateToken.setToken(USER_DATA.token);

// 字典信息
const dicData = localDataDic.get();
export const DIC_DATA = (dicData && dicData.dic) || {};

// 权限信息
const permissionData = localDataPermission.get();
export const PER_DIC = permissionData || [];
