<template>
  <div class="content">
    <div>
      <!-- 筛选 -->
      <e-search @search="getList" @reset="reset" v-model="tableFilters"> </e-search>
      <!-- 内容区 -->
      <div>
        <e-table
          ref="hallTable"
          :options="options"
          :columns="columns"
          row-key="ywh"
          @afterCurrentPageClick="afterCurrentPageClickHandle"
        />
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="45%"
          >
            <div class="tsCont">
                <p> 1、账号注册登录的问题请联系新疆维吾尔自治区人民政府网新疆政务服务网，联系电话：0991-7531791。    </p>
                <p> 2、具体业务咨询请致电12345转各地不动产登记中心。 </p>
                <p> 3、系统故障问题请联系新疆维吾尔自治区不动产登记中心，可以致电12345转自治区不动产登记中心或加qq群：205706559。 </p>

            </div>

              <p style="text-align: center;margin-top: 20px">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
              </p>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
import hall from '@iebdc/api/hall/hall-service';
import { USER_DATA } from '@manager/config/app-data';
import { getOrganBsdt } from '@/pages/iebdc/api/uums';

export default {
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
      address: window.CONFIG.APP_INFO.address,
      systemphone: window.CONFIG.APP_INFO.systemphone,
      zbf: window.CONFIG.APP_INFO.zbf,
      youbian: window.CONFIG.APP_INFO.youbian,
      gzTimeAM: window.CONFIG.APP_INFO.gzTimeAM,
      gzTimePM: window.CONFIG.APP_INFO.gzTimePM,
      sourceCode: 0,
      dialogVisible: true,
      treeDataAll: [],
      djjgOptions: [], //登记机构
      params: {
        nCurrent: 0,
        nSize: 10,
        // djjg: JSON.parse(localStorage.getItem("USER_DATA")).areaCode,
        djjg: 650000,
      }, //表格的查询参数
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 0,
        loading: true,
        //选择框
        mutiSelect: false,
        //有序列
        hasIndex: true,
      },
      columns: [
        {
          prop: 'jgmc',
          label: '登记机构',
        },
        {
          prop: 'officeName',
          label: '办事大厅',
        },
        {
          prop: 'address',
          label: '网点地址',
          type: 'dic',
        },
        {
          prop: 'time',
          label: '上班时间',
          width: '150',
          formatter: (row, column, prop) => {
            return '上午：' + row.workTimeAM + '，下午：' + row.workTimePM;
          },
        },
        {
          prop: 'dh',
          label: '咨询电话',
        },
      ],
      tableFilters: [
        {
          type: 'cascader',
          options: [],
          label: '登记机构',
          placeholder: '登记机构',
          props: {
            label: 'name',
            value: 'code',
            children: 'childrens',
            emitPath: false,
            checkStrictly: true,
          },
          key: 'djjg',
          // value: JSON.parse(localStorage.getItem("USER_DATA")).areaCode, // 接口传参 value 值
          value: 650000, // 接口传参 value 值
        },
      ],
    };
  },
  created() {
    this.query();
    // let areaCode = JSON.parse(localStorage.getItem("USER_DATA")).areaCode;
    let areaCode = 650000;
    getOrganBsdt().then((res) => {
      if (res && res.success) {
        //这里是直接对获取的后台数组进行赋值操作
        this.treeData = res.data.filter((item) => item.code === String(areaCode));
        console.log(res.data);

        this.tableFilters[0].options = this.getJgTree(this.treeData);
        this.djjgOptions = this.getJgTree(this.treeData);
      }
    });
    // if (this.$store.getters.getOrg && this.$store.getters.getOrg.length > 0) {
    //   console.log('1314')
    //   obj = JSON.parse(JSON.stringify(this.$store.getters.getOrg));
    //   console.log(obj);
    //   this.treeData = obj.filter((item) => item.code === String(areaCode));
    //   let treeDataAll = JSON.parse(JSON.stringify(this.$store.getters.getOrg));
    //   this.treeDataAll = treeDataAll.filter((item) => item.code === String(areaCode));
    //   console.log(this.treeData);
    //   this.tableFilters[0].options = this.getJgTree(this.treeData);
    //   this.djjgOptions = this.getJgTree(this.treeData);
    // }
  },
  beforeDestroy() {
    this.$bus.$off('isFromCall');
  },
  mounted() {
    // this.$message.warning('具体业务问题咨询请联系当地登记中心的工作人员，联系方式登录后即可查看（首页——联系我们）');
    // this.$alert(
    //   '1、具体业务问题咨询请联系当地登记中心的工作人员，联系方式登录后即可查看（首页——联系我们）<br/>2、系统故障问题联系新疆维吾尔自治区不动产登记中心，联系电话：0991-8802054或者可以加qq群：205706559<br/>3、账号注册登录的问题请联系新疆维吾尔自治区人民政府网新疆政务服务网，联系电话：0991-7531791',
    //   '提示',
    //   {
    //     confirmButtonText: '确定',
    //     dangerouslyUseHTMLString: true,
    //     callback: (action) => {},
    //   }
    // );
  },
  methods: {
    //改变分页查询
    afterCurrentPageClickHandle(value) {
      this.params.nCurrent = value - 1;
      this.query();
    },
    //查询列表
    query() {
      hall.query(this.params).then((res) => {
        if (res.success === true) {
          this.$refs.hallTable.setTableList(res.data.page);
          this.$refs.hallTable.setTotal(res.data.recordCount);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //条件查询
    getList(data) {
      console.log(data);

      this.params = Object.assign(this.params, data);
      this.params.nCurrent = 0;
      this.query();
    },
    //清空条件
    reset() {
      this.params = {
        nCurrent: 0,
        nSize: 10,
        // djjg: JSON.parse(localStorage.getItem("USER_DATA")).areaCode,
        djjg: 650000,
      };
      // this.tableFilters[0].value = JSON.parse(localStorage.getItem("USER_DATA")).areaCode;
      this.tableFilters[0].value = 650000;
      this.query();
    },
    //重组登记机构树结构
    getJgTree(item) {
      var pPromise = [];
      var grade = {
        index: 0,
        num: item[0].grade,
      };
      for (var i = 0; i < item.length; i++) {
        if (grade.num > item[i].grade) {
          grade = {
            index: i,
            num: item[i].grade,
          };
        }
      }
      pPromise[0] = item[grade.index];
      this.repeatFor(pPromise);
      return pPromise;
    },

    repeatFor(item) {
      for (var i = 0; i < item.length; i++) {
        if (item[i].code.indexOf('-') >= 0 || item[i].status == 0) {
          item.splice(i, 1);
          i--;
        } else {
          if (item[i].childrens && item[i].childrens.length > 0) {
            this.repeatFor(item[i].childrens);
          } else {
            // 去除暂时无数据展示
            item[i].childrens = undefined;
          }
        }
      }
    },
    //重组登记机构树结构END
    //办事大厅树结构
    getBsdtTree: function (data) {
      let item = JSON.parse(JSON.stringify(this.treeDataAll));
      // console.log("data=====",data);
      // console.log("item====",item);
      this.bsdtOptions = [];
      this.parentTree = {};
      if (!item || item === 'undefined') {
        return;
      }
      if (item.childrens && item.childrens.length < 0) {
        return;
      }
      for (var j = 0; j < item.length; j++) {
        if (item[j].code === data) {
          this.repeatForBsdt(item[j]);
          // 选择登记机构之后默认展示第一个办事大厅的数据
          // this.addressForm.officeCode = this.bsdtOptions[0].code;
          // this.addressForm.officeName = this.bsdtOptions[0].name;
        } else {
          this.findDataTree(item[j], data);
        }
      }
      // 选中数据后收起登记机构的下拉
      if (this.$refs.djjgRef) {
        this.$refs.djjgRef.dropDownVisible = false;
      }
    },
    //获取办事大厅所在层级的树
    findDataTree(subitem, data) {
      // console.log("data3333333333333",subitem.childrens);
      if (subitem.childrens && subitem.childrens.length > 0) {
        for (var i = 0; i < subitem.childrens.length; i++) {
          if (subitem.childrens[i].code == data && subitem.childrens[i].status != 0) {
            this.parentTree = subitem.childrens[i];
            this.repeatForBsdt(subitem.childrens[i]);
            // 选择登记机构之后默认展示第一个办事大厅的数据
            // this.addressForm.officeCode = this.bsdtOptions[0].code;
            // this.addressForm.officeName = this.bsdtOptions[0].name;
            return;
          } else {
            this.findDataTree(subitem.childrens[i], data);
          }
        }
      }
    },
    //将找到的办事大厅放入集合中
    repeatForBsdt(subitem) {
      // console.log("subitem44444444444",subitem);
      if (subitem.childrens && subitem.childrens.length > 0) {
        for (var i = 0; i < subitem.childrens.length; i++) {
          // console.log("subitem.childrens",subitem.childrens[i]);
          if (subitem.childrens[i].code.indexOf('-') >= 0 && subitem.childrens[i].status != 0) {
            this.bsdtOptions.push(subitem.childrens[i]);
          } else {
            this.repeatForBsdt(subitem.childrens[i]);
          }
        }

        // console.log("subitem.childrens[i]",this.bsdtOptions);
      }
    },
    //办事大厅树结构END
    bsdtChange() {
      //办事大厅点击事件
      if (this.$refs.bsdtRef) {
        this.$refs.bsdtRef.dropDownVisible = false;
      }
    },
    getDjjgName(list, code) {
      // 根据登记机构获取登记机构对应的名称
      list.forEach((item) => {
        if (item.code == code) {
          this.addressForm.jgmc = item.name;
          return;
        } else {
          if (item.childrens) {
            this.getDjjgName(item.childrens, code);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/link.scss';
.content {
  min-height: 500px;
}
/deep/ .el-table__body-wrapper {
  height: auto !important;
}
.table-filter {
  height: 100%;
  margin: 20px 0;
}
.tsCont {
  padding: 10px;
  p {
    line-height: 30px;
  }
}
</style>
